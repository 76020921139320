import React from "react";
import { node, string } from "prop-types";
import styles from "./question-header.module.css";

/**
 * Used for questions.  Bold text, with an emoji lined up to the left.
 */

export default function QuestionHeader(props) {
  let className = styles.container;
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <div className={className}>
      <div className={styles.iconContainer}>{props.icon}</div>
      <div className={styles.questionContainer}>{props.children}</div>
    </div>
  );
}

QuestionHeader.propTypes = {
  icon: node,
  children: node,
  className: string
};
