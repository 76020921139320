import React, { Component } from "react";
import { func } from "prop-types";
import { navigate } from "gatsby";
import { connect } from "react-redux";
import { Button } from "@instructure/ui-buttons";
import IconArrowOpenEnd from "@instructure/ui-icons/lib/IconArrowOpenEndSolid";
import IconArrowOpenStart from "@instructure/ui-icons/lib/IconArrowOpenStartSolid";
import { FormFieldGroup } from "@instructure/ui-form-field";
import { Checkbox } from "@instructure/ui-forms";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import QuestionHeader from "../../../components/question-header";
import styles from "./rnp-unsure.module.css";
import { setCurrentModule, setCurrentStep } from "../../../state/actions";

export class ReasonableAndPrudent extends Component {
  static propTypes = {
    handleNavigate: func,
    goToStep: func
  };

  state = {
    hasSubmitted: false,
    checkedBoxes: []
  };

  componentDidMount() {
    // Make sure that the progress bar always shows
    this.props.handleNavigate(1);
    this.props.goToStep(5);
  }

  updateValue = newVal => {
    if (this.state.checkedBoxes.includes(newVal)) {
      this.setState({
        checkedBoxes: this.state.checkedBoxes.filter(x => x !== newVal)
      });
    } else {
      this.setState({
        checkedBoxes: this.state.checkedBoxes.concat([newVal])
      });
    }
  };

  handleActivate = id => {
    if (this.state.openItems.includes(id)) {
      this.setState({
        openItems: this.state.openItems.filter(x => x !== id)
      });
    } else {
      this.setState({
        openItems: this.state.openItems.concat(id)
      });
    }
  };

  onSubmit = () => {
    if (!this.state.hasSubmitted) {
      this.setState({ hasSubmitted: true });
    } else {
      this.props.goToStep(6, "/modules/normalcy/high-risk");
    }
  };

  goBack = () => {
    if (this.state.hasSubmitted) {
      this.setState({ hasSubmitted: false });
    } else {
      this.props.goToStep(
        4,
        "/modules/normalcy/reasonable-and-prudent-activity"
      );
    }
  };

  render() {
    return (
      <Layout isModule>
        <SEO
          title="Module - Normalcy - Reasonable & Prudent Parenting Standards"
          keywords={["utah foster care", "foster care"]}
        />
        <div className={styles.moduleGridContainer}>
          <p className={styles.openingParagraph}>
            <b>What if you’re unsure?</b>
            <br />
            <br /> Sometimes, even after careful consideration, you might not be
            sure if an activity is appropriate. Maybe:
            <ul className={styles.bullettedList}>
              <li>
                The child hasn’t been in your care very long and you don’t know
                their behavioral history.
              </li>
              <li>You don’t have the child’s medical history.</li>
              <li>
                The activity itself has a lot of variables – for example, a
                week-long backpacking trip in the care of another adult.
              </li>
            </ul>
          </p>
          <div className={styles.questionArea}>
            <FormFieldGroup
              description={
                <QuestionHeader
                  icon={
                    <span
                      className={styles.emoji}
                      role="img"
                      aria-label="Thinking Face"
                    >
                      🤔
                    </span>
                  }
                >
                  <span>
                    If you’re unsure if an activity is appropriate, what should
                    you do?
                  </span>
                </QuestionHeader>
              }
            >
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  label={
                    <span>
                      Say yes – err on the side of opportunity & experience
                    </span>
                  }
                  value="yes"
                  checked={this.state.checkedBoxes.includes("yes")}
                  onChange={event => this.updateValue(event.target.value)}
                />
              </div>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  label={<span>Say no – err on the side of caution</span>}
                  value="no"
                  checked={this.state.checkedBoxes.includes("no")}
                  onChange={event => this.updateValue(event.target.value)}
                />
              </div>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  label={<span>Ask another parent</span>}
                  value="parent"
                  checked={this.state.checkedBoxes.includes("parent")}
                  onChange={event => this.updateValue(event.target.value)}
                />
              </div>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  label={<span>Ask your caseworker</span>}
                  value="caseworker"
                  checked={this.state.checkedBoxes.includes("caseworker")}
                  onChange={event => this.updateValue(event.target.value)}
                />
              </div>
            </FormFieldGroup>
          </div>
          {this.state.hasSubmitted && (
            <div className={styles.feedbackBlurb}>
              Discussing with another parent can be helpful. But when you’re
              unsure, contact the caseworker so you don’t feel like you have the
              full weight of responsibility on your shoulders. Decide together
              so you have the family team backing up your decision.
            </div>
          )}
          <div className={styles.backButtonArea}>
            <Button
              onClick={this.goBack}
              variant="success"
              icon={IconArrowOpenStart}
            />
          </div>
          <div className={styles.forwardButtonArea}>
            <Button
              onClick={this.onSubmit}
              variant="success"
              icon={IconArrowOpenEnd}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  ...ownProps
});

const dispatchToProps = dispatch => {
  return {
    handleNavigate(moduleId) {
      dispatch(setCurrentModule(moduleId));
    },
    goToStep(stepId, navigateTo) {
      dispatch(setCurrentStep(stepId));
      if (navigateTo) {
        navigate(navigateTo);
      }
    }
  };
};

export default connect(
  stateToProps,
  dispatchToProps
)(ReasonableAndPrudent);
